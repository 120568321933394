<template>

<div id="app" class=" pb-5 pt-4 position-relative" ref="fullscreenElement" >
    <span class=" position-absolute top-0 end-0 me-4 mt-4">
      <i class="bi bi-fullscreen" @click="toggleFullScreen"></i>
    </span>
        <div class="clock position-absolute top-50 start-50 translate-middle w-100" >
          <!-- <button >Toggle Full Screen</button> -->
          <p class="clock">www.mfz.cl 
          </p>
          <Clock class="clock" />
          <Timer class="clock"/>
        </div>
 </div>
</template>

<script>
import Clock from './components/Clock.vue'
import Timer from './components/Timer.vue'

export default {
  name: 'App',
  components: {
    Clock,
    Timer
  },

  methods: {
    toggleFullScreen() {
      const elem = this.$refs.fullscreenElement;
      
      if (!document.fullscreenElement && !document.webkitFullscreenElement && !document.mozFullScreenElement && !document.msFullscreenElement) {
        // Entra en modo pantalla completa
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { // Firefox
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { // Chrome, Safari y Opera
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { // IE/Edge
          elem.msRequestFullscreen();
        }else{
          alert('Dispositivo no compatible')
        }
      } else {
        // Salir del modo pantalla completa
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { // Firefox
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { // Chrome, Safari y Opera
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { // IE/Edge
          document.msExitFullscreen();
        }else{
          alert('Dispositivo no compatible')
        }
      }
    }
  }  
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center; */
  color: #2c3e50;
  background: #0f3854;
  background: radial-gradient(ellipse at center, #0a2e38 36%, #000000 70%);
  background-size: 100%;
 
}
  .fullscreen {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0f3854;
    background: radial-gradient(ellipse at center, #0a2e38 36%, #000000 70%);
    background-size: 100%;
  }

</style>
