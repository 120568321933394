<template>
      <div id="clock">
      
      <div class="container ">
          <div class="row">
              <div class="col-12">
                  <p class=" h2">{{ date }}</p>
              </div>
          </div>

          <div class="sprite-container ">  
            <div class="position-absolute top-50 end-0 translate-middle-y pe-3 h3  d-none d-sm-block" @click="toogleReloVoltear">
                 <i v-if="esReloVoltear" class="bi bi-layers"></i>
                 <i v-else class="bi bi-layers-fill"></i>

            </div>


            <div :class="[esReloVoltear?'d-sm-block':'','d-none  p-2']">
                <div class="row">
                    <div class="col-sm-4 col-xs-12">
                        <div class="number" :class="d_hoursCss"></div>
                        <div class="number" :class="u_hoursCss"></div>
                    </div>  
                <div class="col-sm-4 col-xs-12">
                    <div class="number" :class="d_minutesCss"></div>
                    <div class="number" :class="u_minutesCss"></div>
                    </div>
                    <div class="col-sm-4 col-xs-12">
                        <div class="number" :class="d_secondsCss"></div>
                        <div class="number" :class="u_secondsCss"></div>   
                    </div>
                </div>
            </div>
            <div :class="[esReloVoltear?'d-sm-none':'','d-block' ]">            
                    <div class="row align-items-center justify-content-center clock-font-STM ]">
                        <div class="col-sm-3 col-xs-12 size-number-clock fw-bolder">
                            <!-- <i class="bi bi-0-square"></i>
                            <i class="bi bi-1-square"></i> -->
                            {{ hours }}
                        </div>  
                        <div class="col-sm-1 d-none d-sm-block h1 fw-bolder ">:</div>
                    <div class="col-sm-4 col-xs-12 size-number-clock fw-bolder">
                                {{ minutes }}
                        </div>
                        <div class="col-sm-1 d-none d-sm-block h1 fw-bolder">:</div>
                        <div class="col-sm-3 col-xs-12 size-number-clock fw-bolder">
                            {{ seconds }}
                        </div>
                    </div>

            </div>
          </div>
      </div>        

  </div>
</template>


<script>
import { ref,watch } from 'vue'



export default {
    name:'Clock-Item',
    setup() {
        const time = ref('');
        const hours = ref('');
        const minutes = ref('');
        const seconds = ref('');
        const date = ref('');
        const u_secondsCss = ref('');
        const d_secondsCss = ref('');
        const u_minutesCss = ref('');
        const d_minutesCss = ref('');
        const u_hoursCss = ref('');
        const d_hoursCss = ref('');
        // const esReloVoltear =  ref(false);

        const week = ['DOMINGO', 'LUNES', 'MARTES', 'MIERCOLES', 'JUEVES', 'VIERNES', 'SÁBADO'];

        function zeroPadding(num, digit) {
            return String(num).padStart(digit, '0');
        }

        function updateTime() {
            const cd = new Date();
            time.value = `${zeroPadding(cd.getHours(), 2)}:${zeroPadding(cd.getMinutes(), 2)}:${zeroPadding(cd.getSeconds(), 2)}`;
            hours.value = zeroPadding(cd.getHours(), 2);
            minutes.value = zeroPadding(cd.getMinutes(), 2);
            seconds.value = zeroPadding(cd.getSeconds(), 2);
            date.value = `${week[cd.getDay()]} ${zeroPadding(cd.getDate(), 2)}/${zeroPadding(cd.getMonth() + 1, 2)}/${zeroPadding(cd.getFullYear(), 4)}`;
        }

        // SECONDS
        watch(
            () => seconds.value.slice(-1),
            (newVal) => {
                u_secondsCss.value = `number-${newVal}_5`;
                setTimeout(() => {
                    u_secondsCss.value = `number-${newVal}`;
                }, 150);
            },
            { immediate: true }
        );

        watch(
            () => seconds.value.slice(0, 1),
            (newVal) => {
                d_secondsCss.value = `number-${newVal}_5`;
                setTimeout(() => {
                    d_secondsCss.value = `number-${newVal}`;
                }, 150);
            },
            { immediate: true }
        );

        // MINUTES
        watch(
            () => minutes.value.slice(-1),
            (newVal) => {
                u_minutesCss.value = `number-${newVal}_5`;
                setTimeout(() => {
                    u_minutesCss.value = `number-${newVal}`;
                }, 150);
            },
            { immediate: true }
        );

        watch(
            () => minutes.value.slice(0, 1),
            (newVal) => {
                d_minutesCss.value = `number-${newVal}_5`;
                setTimeout(() => {
                    d_minutesCss.value = `number-${newVal}`;
                }, 150);
            },
            { immediate: true }
        );

        // HOURS
        watch(
            () => hours.value.slice(-1),
            (newVal) => {
                u_hoursCss.value = `number-${newVal}_5`;
                setTimeout(() => {
                    u_hoursCss.value = `number-${newVal}`;
                }, 150);
            },
            { immediate: true }
        );

        watch(
            () => hours.value.slice(0, 1),
            (newVal) => {
                d_hoursCss.value = `number-${newVal}_5`;
                setTimeout(() => {
                    d_hoursCss.value = `number-${newVal}`;
                }, 150);
            },
            { immediate: true }
        );

        setInterval(updateTime, 1000);
        updateTime();

        return {
            time,
            date,
            hours,
            minutes,
            seconds,
            u_secondsCss,
            d_secondsCss,
            u_minutesCss,
            d_minutesCss,
            u_hoursCss,
            d_hoursCss
        }
    },methods:{
        toogleReloVoltear(){
            this.esReloVoltear=!this.esReloVoltear;
        }
    }
     ,data() {
        return {
            esReloVoltear:false
        }
     }
    //     return {
    //         time,
    //         date,
    //         hours,
    //         minutes,
    //         seconds,
    //         u_secondsCss,
    //         d_secondsCss,
    //         u_minutesCss,
    //         d_minutesCss,
    //         u_hoursCss,
    //         d_hoursCss
    //     };
    // }
};

//app.mount('#clock');
</script>