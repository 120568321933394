<template>
  <div class="timer">
          
              <p v-if='!isToCreate' class="h5"   @click="addOne">
                  <i   class="bi bi-clock-history me-2">

                    <span v-if="countTimers==0">
                         Agrega Temporizador
                    </span>  
                     
                  </i>    
                 
                  <i class="bi bi-plus-circle"></i>
              </p>           
            
              <div  v-for="timer in timerList.slice().reverse()" :key="timer.id">
                  <div  v-if="timer.isCreated">                 
                        <div class="row mt-1 px-0 d-flex align-items-center justify-content-center">
                            <div class="col-4 text-end">                      
                                <p class="h4 mb-0">
                                    <span class="h2 link-danger">
                                        <i class="bi bi-trash"  @click="remove(timer)"></i>
                                    </span>           
                                    {{timer.initialTime}}min
                                </p>
                            </div>
                            <div class="col-4">       
                              
                              <div class="row align-items-center">

                                      <div class="col-8 ">
                                        <div v-if="timer.percentCompleted()<100" class="progress" role="progressbar" aria-label="Info example" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                                              <div class="progress-bar bg-info" :style="'width:'+timer.percentCompleted()+'%'"></div>
                                          </div>                                 
                                          <div v-else>
                                            FIN
                                            <audio v-if='timer.isSoundON' id="audio" autoplay src="../assets/sound/bocina _de_aire .mp3"></audio>
                                          </div>
                                      </div>
                                      <div class="col-4 ">
                                        <span @click="timer.tooggleSound">
                                          <i v-if='timer.isSoundON' class="bi bi-volume-up-fill h2"></i>
                                          <i v-else class="bi bi-volume-mute-fill h2"></i>
                                        </span>
                                      </div>
                             </div>

                            </div>
                            <div class="col-4 text-start">
                                <p class="h4 mb-0">{{ timer.timeLeftToFormatTime() }}
                                    <span class="h2" v-if="timer.percent<100">
                                        <i v-if="timer.isRunning" class="bi bi-pause-circle" @click="timer.stopTimer"></i>
                                        <i v-else class="bi bi-play-fill"  @click="timer.startTimer"></i>
                                    </span>     
                                    <span v-else class="h2" @click="timer.reload">
                                      <i class="bi bi-arrow-clockwise"></i>
                                    </span>
                                </p>
                            </div>
                        </div>

                  </div>
                  <span v-else class="mt-4">
                    <span >
                      <i   class="bi bi-clock-history me-2">Temporizador en :</i> 
                    </span>
                      <div class="row mt-2 px-3 d-flex align-items-center justify-content-center ">                           
                        <div class="col-12 center-block p-0">
                         
                          <div class="input-group align-items-center justify-content-center">
                            <button class="btn btn-outline-secondary btn-clock-border-color btn-lg" type="button" @click="timer.setTime(1)">1m</button>
                            <button class="btn btn-outline-secondary btn-clock-border-color btn-lg" type="button" @click="timer.setTime(10)">10m</button>
                            <button class="btn btn-outline-secondary btn-clock-border-color btn-lg" type="button" @click="timer.setTime(20)">20m</button>
                            <button class="btn btn-outline-secondary btn-clock-border-color btn-lg" type="button" @click="timer.setTime(30)">30m</button>
                            <button class="btn btn-outline-secondary btn-clock-border-color btn-lg" type="button" @click="timer.setTime(60)">60m</button>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-1 px-3 d-flex align-items-center justify-content-center ">             
                          
                            <div class="col-12 center-block ">                      
                              <div class="input-group mb-3 align-items-center justify-content-center">                             
                                 

                                <span class="h2 link-danger mt-3 me-4">
                                  <i class="bi bi-trash" @click="remove(timer)"></i>
                                </span>  
                                <button class="btn btn-outline-secondary" type="button" @click="timer.lessTime(1)">-1</button>
                                <!-- <input type="number" class="form-control" placeholder="" aria-label="Example text with two button addons" style="max-width: 60px;"> -->
                                <span class="mx-3  h2"> {{ timer.timeLeft }}min </span>
                                <button class="btn btn-outline-secondary" type="button" @click="timer.addTime(1)">+1</button>
                                <span class="h2 link-success mt-3 ms-4">
                                  <i class="bi bi-play-fill" @click="timer.startTimer"></i>
                                </span>     
                                
                              </div>
                            </div>

                            <div class="col">
                              <!-- <span class="h2 link-danger mt-3 me-4">
                                  <i class="bi bi-trash" @click="remove(timer)"></i>
                                </span>  
                                <span class="h2 link-success mt-3 ms-4">
                                  <i class="bi bi-play-fill" @click="timer.startTimer"></i>
                                </span>      -->
                            </div>
                            
                        </div>
                  </span>


              </div>    

              <!-- <div class="row mt-2 px-3 d-flex align-items-center justify-content-center">
                  <div class="col-12 text-center">
                      <p class="h1">
                          <i class="bi bi-plus-circle"  @click="addOne"></i>
                      </p>

                  </div>
              </div>    -->
  </div>
  
</template>

<script>
import { ref,computed } from 'vue'

export default {
  name: 'Timer-Item',
  // setup(){
  //   const Timer =function(){
  //             that = this;
  //             this.timeLeft=10; // Tiempo inicial en segundos
  //             this.initialTime=10;
  //             this.timer= null;
  //             this.isRunning= false;

  //             this.startTimer=function() {
  //             if (!this.isRunning) {
  //               that.isRunning = true;
  //               that.timer = setInterval(() => {
  //                 if (that.timeLeft > 0) {
  //                   that.timeLeft--;
  //                 } else {
  //                   that.stopTimer();
  //                 }
  //               }, 1000);
  //               }
  //             };
  //           this.stopTimer=function() {
  //             clearInterval(that.timer);
  //             that.isRunning = false;
  //             };
  //           this.resetTimer=function() {
  //             that.stopTimer();
  //             that.timeLeft = that.initialTime;
  //           }
  //       };
  //   const timerList=[];

  // },
  data() {
    return {
  
      Timer :function(){
              const that = this;
              this.id=(new Date()).getTime();
              this.timeLeft=5; // Tiempo inicial en segundos
              this.isSoundON=true;

              this.initialTime=10;
              this.timer= null;
              this.isRunning= false;
              this.isCreated=false;
              this.percent=0;

              this.startTimer=function() {
                if(!that.isCreated){
                 
                  that.initialTime=that.timeLeft;
                  that.timeLeft=that.timeLeft*60;
                }

                that.isCreated=true;
              if (!that.isRunning) {
                that.isRunning = true;
                that.timer = setInterval(() => {
                  if (that.timeLeft > 0) {
                    that.timeLeft--;
                    that.percent=that.percentCompleted();
                  } else {
                    that.stopTimer();
                  }
                }, 1000);
                }
              };
            this.stopTimer=function() {
              clearInterval(that.timer);
              that.isRunning = false;
              };
            this.resetTimer=function() {
              that.stopTimer();
              that.timeLeft = that.initialTime;
            }
            this.setTime=function(time){
              that.timeLeft=time;
            }
            this.addTime=function(time){
              that.timeLeft+=time;
            }
            this.lessTime=function(time){
              if( (that.timeLeft-time)<0){
                that.timeLeft=1;
              }else{
                that.timeLeft-=time;
              }
            }
            this.percentCompleted=function(){
                return ((that.initialTime*60)-that.timeLeft)*100/(that.initialTime*60);
            }
            this.timeLeftToFormatTime=function(){
              const minutes = Math.floor(that.timeLeft / 60);
              const remainingSeconds = that.timeLeft % 60;
              const formattedMinutes = String(minutes).padStart(2, '0');
              const formattedSeconds = String(remainingSeconds).padStart(2, '0');
              return `${formattedMinutes}:${formattedSeconds}`;
            }
            this.tooggleSound=function(){
              that.isSoundON=!that.isSoundON;
            }
            this.reload=function(){
              that.timeLeft=that.initialTime*60;
              that.startTimer();
            }
        },
        timerList:ref([]),
        isToCreate: computed(() => this.timerList.some((t)=>!t.isCreated)),
        countTimers: computed(() => this.timerList.length),
         // esta por crear una

    }
  },
  methods: {
     isOneForCreated(){
        return this.timerList.some((t)=>!t.isCreated);
     },
     addOne (){
       if(this.timerList.some((t)=>!t.isCreated)){
         return false;  
       }
      this.timerList.push(new this.Timer())
     },
     remove(timer){
      timer.stopTimer();
      this.timerList=this.timerList.filter((t)=>t.id!==timer.id);

     },
  },
  beforeDestroy() {
    this.timerList.forEach(t=>{
        t.stopTimer();
    })
  }
}
</script>


